<template>
  <Page
    has-actions
    :subtitle="subtitle"
    :loading="loading"
  >
    <b-switch
      slot="actions"
      :disabled="
        (items.active && !this.$can('delete', 'purchasing.carriers')) ||
          (!items.active && !$can('update', 'purchasing.carriers'))
      "
      :value="items.active"
      type="is-success"
      @input="activateOrDesactivateCarrier"
    >
      {{ $tc("active", 1) }}
    </b-switch>
    <Card content-class="px-0 py-1">
      <div
        v-if="loading"
        style="min-height: 200px"
      >
        <b-loading
          v-model="loading"
          :is-full-page="false"
        />
      </div>
      <b-tabs
        v-else
        v-model="activeTab"
        expanded
        :class="activeTab == 'tarification' ? 'paddingless' : ''"
      >
        <b-tab-item
          :label="$t('general')"
          value="general"
        >
          <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            tag="div"
          >
            <form
              method="post"
              autocomplete="off"
              @submit.prevent="handleSubmit(saveCarrier)"
            >
              <div class="columns is-multiline is-mobile">
                <div class="column is-9-tablet is-12-mobile">
                  <div class="columns is-multiline is-mobile mt-2">
                    <div class="column is-3-tablet is-6-mobile">
                      <b-select-validation
                        v-model="items.type"
                        vid="type"
                        :disabled="!$can('update', 'purchasing.carriers')"
                        :label="$t('type')"
                        rules="required"
                      >
                        <template>
                          <option
                            v-for="opt in types"
                            :key="opt.key"
                            :value="opt.key"
                          >
                            {{ opt.label }}
                          </option>
                        </template>
                      </b-select-validation>
                    </div>
                    <b-input-validation
                      v-model="items.name"
                      :label="$tc('name',1)"
                      :placeholder="placeholders.carrier.name"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      rules="required|max:30"
                      class="column is-4-tablet is-6-mobile"
                    />
                    <b-input-validation
                      v-model="items.product"
                      :label="$tc('product',1)"
                      :placeholder="placeholders.carrier.product"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      rules="required|max:25"
                      class="column is-5-tablet is-6-mobile"
                    />
                    <b-input-validation
                      v-model="items.code"
                      :label="`${$tc('code',1)} (${$t('optional')})`"
                      :placeholder="placeholders.carrier.code"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      rules="max:10"
                      class="column is-3-tablet is-6-mobile"
                    />
                    <!-- <b-input-validation
              v-model="items.code_mp"
              :label="`${$t('code_mp')} (${$t('optional')})`"
              :placeholder="placeholders.carrier.code_mp"
              :disabled="!$can('update', 'purchasing.carriers')"
              rules="max:10"
              class="column is-3-tablet is-6-mobile"
            />         -->
                    <!-- <b-input-validation
              v-model="items.memo"
              :label="`${$t('memo')} (${$t('optional')})`"
              :placeholder="placeholders.carrier.memo"
              :disabled="!$can('update', 'purchasing.carriers')"
              rules="max:150"
              class="column is-6-tablet is-12-mobile"
            />  -->
                    <b-input-validation
                      v-model="items.tracking_link"
                      :label="`${$t('tracking_link')} (${$t('optional')})`"
                      :placeholder="placeholders.carrier.tracking_link"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      rules="max:255"
                      class="column is-9-tablet is-12-mobile"
                    />
                    <b-input-validation
                      v-model="items.rank"
                      :label="$t('rank')"
                      :placeholder="placeholders.carrier.rank"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      rules="required|numeric"
                      class="column is-3-tablet is-6-mobile"
                    />
                    <b-input-validation
                      v-model="items.max_kg"
                      :label="`${$t('max_weight')} (${$t('optional')})`"
                      :placeholder="placeholders.number[3]"
                      suffix="Kg"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      rules="numeric"
                      class="column is-3-tablet is-6-mobile"
                    />
                    <!-- <div class="column is-3-tablet is-6-mobile">
                  <b-field>
                    <template #label>
                      <b-tooltip
                        type="is-dark"
                        label="Help text here for explanation"
                      >
                        {{ $t('ship_before') + " ("+ $t('optional') +")" }}
                        <b-icon
                          size="is-small"
                          icon="help-circle-outline"
                        /> 
                      </b-tooltip>
                    </template>
                    <b-timepicker
                      v-model="items.ship_before"
                      icon="clock"
                      placeholder="Click to select..."
                      enable-seconds
                    /> 
                  </b-field>
                </div> -->

                    <b-input-validation
                      v-model="items.ship_before"
                      :label="`${$t('ship_before')} (${$t('optional')})`"
                      :placeholder="placeholders.carrier.time"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      class="column is-3-tablet is-6-mobile"
                    />
                    <b-input-validation
                      v-model="items.packed_within"
                      :label="`${$t('packed_within')} (${$t('optional')})`"
                      :placeholder="placeholders.number[1]"
                      :suffix="$tc('day', 2)"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      rules="numeric"
                      class="column is-3-tablet is-6-mobile"
                    />
                    <b-input-validation
                      v-model="items.max_box"
                      :label="`${$t('max_weight_carton')} (${$t('optional')})`"
                      :placeholder="placeholders.number[1]"
                      :disabled="!$can('update', 'purchasing.carriers')"
                      suffix="Kg"
                      rules="numeric"
                      class="column is-3-tablet is-6-mobile"
                    />
                    <!-- <div class="column is-3-tablet is-6-mobile mt-2  mb-4">
                      <b-switch
                        v-model="items.pickup"
                      >
                        {{ $t("pickup_point") }}
                      </b-switch>
                    </div> -->
                    <div class="column is-3-tablet is-6-mobile mt-2 mb-4">
                      <b-switch
                        v-model="items.notify_salesman"
                      >
                        {{ $t("notify_salesman") }}
                      </b-switch>
                    </div>
                    <div class="column is-3-tablet is-6-mobile mt-2 mb-4">
                      <b-switch
                        v-model="items.proforma"
                      >
                        {{ $t("invoice_car") }}
                      </b-switch>
                    </div>
                    <!-- <div class="column is-3-tablet is-6-mobile mt-2 ">
                      <b-switch
                        v-if="$can('delete', 'purchasing.carriers')"
                        v-model="items.active"
                      >
                        {{ $tc("active", 1) }}
                      </b-switch>
                    </div> -->
                    <div class="column is-12-tablet is-12-mobile">
                      <ImageUpload
                        v-if="renderComponent"
                        :images="
                          logo
                        "
                        :max-files="1"
                        :label-extra="extra_label"
                        @updateFiles="onUpdateFiles"
                      />
                    </div>
                  </div>
                </div>
                <div class="column is-3-tablet is-12-mobile">
                  <div
                    class="columns is-multiline is-mobile bg-cream mt-4"
                  >
                    <div class="column is-12 is-size-6">
                      <b>{{ `${$tc("code_mp",2)} (${$t('optional')})` }}</b>
                    </div>
                    
                    <div
                      v-for="mp in codes_mp"
                      :key="mp.id"
                      class="column is-12"
                    >
                      <b-input-validation
                        v-model="mp.code"				  
                        :label="mp.label"
                        :placeholder="placeholders.carrier.code_mp"
                        :disabled="!$can('update', 'purchasing.carriers')"
                        rules="max:128"
                        hide-details
                      />
                    </div>
                  </div>
                </div>
                <div class="column">
                  <b-button
                    type="is-primary"
                    :disabled="save_carrier_loading"
                    :loading="save_carrier_loading"
                    tag="input"
                    native-type="submit"
                    :value="$t('save')"
                  >
                    {{ $t("save") }}
                  </b-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </b-tab-item>
        <b-tab-item
          :label="$t('pricing')"
          value="tarification"
          :disabled="!pricing"
        >
          <div class="columns is-mobile is-multiline">
            <div class="column is-12">
              <b-tabs
                v-model="activeSite"
                class="mb-0 paddingless"
              >
                <b-tab-item
                  v-for="(site, i) in pricing"
                  :key="site.id"
                  :value="i+''"
                >
                  <span slot="header">
                    <img
                      :src="'/img/sites/' + site.id + '.svg'"
                      width="20"
                      height="20"
                      alt
                      class="mr-2"
                    >
                    {{ site.label }}
                  </span>
                  <template>
                    <!-- <ValidationObserver
                      ref="observer"
                      :key="`slide_${site.id}_`"
                      slim
                    > -->
                    <div class="columns is-multiline is-mobile">
                      <b-tabs
                        class="column is-12 is-flex-wrap-nowrap mb-0"
                        :class="
                          $device.mobile ? 'paddingless-left' : ''
                        "
                        :vertical="!$device.mobile"
                      >
                        <b-tab-item
                          v-for="sld in site.tlds"
                          :key="'country-' + sld.country.iso"
                          header-class="mb-4"
                          class="ml-5"
                        >
                          <template slot="header">
                            <img
                              :src="'/img/flags/' + sld.country.iso + '.svg'"
                              width="18"
                              height="18"
                              class="mr-2"
                            >
                            {{ sld.country.label }}
                          </template>

                          <div class="columns">
                            <div class="column is-4">
                              <b-field
                                :label="$tc('country',2)"
                              >
                                <b-autocomplete
                                  ref="taginput"
                                  v-model="newCountry"
                                  type="is-default"
                                  expanded
                                  autocomplete
                                  field="country.label"
                                  ellipsis
                                  :data="filteredCountries"
                                  :placeholder="$t('choose')"
                                  :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                  @select="option => setCountryPricing(option, sld)"
                                  @typing="getCountries"
                                >
                                  <template v-slot="props">
                                    {{ props.option.label }}
                                  </template>
                                </b-autocomplete>
                              </b-field>
                            </div>
                          </div>
                          <ValidationObserver
                            ref="observer"
                            v-slot="{ handleSubmit }"
                            class="mt-2"
                            tag="div"
                          >
                            <form
                              method="post"
                              autocomplete="off"
                              @submit.prevent="handleSubmit(() => savePricing(sld))"
                            >
                              <div
                                v-for="country in sld.pricing"
                                :key="country.id"
                              >
                                <div
                                  class="columns is-multiline is-mobile mb-0"
                                  :class="country.country.iso == 0 ? 'bg-deleted' : null"
                                >
                                  <div class="column is-12 pb-1 is-align-items-center is-flex is-justify-content-space-between">
                                    <b class="is-size-6">{{ country.country.label }}</b>

                                    <div
                                      v-if="$can('update', 'purchasing.carriers.pricing')"
                                      class="is-clickable"
                                      @click="deleteCountryPricing(country, sld.pricing, sld)"
                                    >
                                      <b-icon
                                        icon="delete"
                                        type="is-danger"
                                        custom-size="mdi-18px"
                                      />
                                    </div>
                                  </div>
                                  <b-input-validation
                                    v-model="country.rate"
                                    :label="$t('price')"
                                    :placeholder="placeholders.number.decimal_2"
                                    :suffix="sld.currency.symbol"
                                    :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                    rules="required"
                                    class="column is-2-tablet is-6-mobile"
                                    :class="$device.mobile ? 'pb-0' : null"
                                  />
                                  <b-input-validation
                                    v-model="country.extra_rate"
                                    :label="$t('extra_rate')"
                                    :placeholder="placeholders.number.decimal_2"
                                    :suffix="sld.currency.symbol"
                                    :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                    rules="required"
                                    class="column is-2-tablet is-6-mobile"
                                    :class="$device.mobile ? 'pb-0' : null"
                                  />
                                  <b-input-validation
                                    v-model="country.rate_new_box"
                                    :label="$t('rate_new_box')"
                                    :placeholder="placeholders.number.decimal_2"
                                    :suffix="sld.currency.symbol"
                                    :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                    rules="required"
                                    class="column is-2-tablet is-6-mobile"
                                    :class="$device.mobile ? 'pb-0' : null"
                                  />
                                  <b-input-validation
                                    v-model="country.delivery_time"
                                    :label="$t('delivery_time')"
                                    :placeholder="placeholders.number[2]"
                                    suffix="h"
                                    :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                    rules="required|numeric"
                                    class="column is-2-tablet is-6-mobile"
                                    :class="$device.mobile ? 'pb-0' : null"
                                  />
                                  <b-input-validation
                                    v-model="country.free_from"
                                    :label="`${$t('free_from')} (${$t('optional')})`"
                                    :placeholder="placeholders.number.decimal_2"
                                    :suffix="sld.currency.symbol"
                                    :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                    class="column is-2-tablet is-6-mobile"
                                  />
                                  <b-input-validation
                                    v-model="country.cashback"
                                    :label="`${$t('cashback')} (${$t('optional')})`"
                                    :placeholder="placeholders.number.decimal_2"
                                    :suffix="sld.currency.symbol"
                                    :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                    class="column is-2-tablet is-6-mobile"
                                  />                                  
                                  <b-input-validation
                                    v-model="country.brands_free_from"
                                    :label="`${$t('brands_free_from')} (${$t('optional')})`"
                                    :placeholder="placeholders.number[3]"
                                    :suffix="sld.currency.symbol"
                                    :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                    class="column is-2-tablet is-6-mobile pt-0"
                                  />
                                  <div class="column is-6-tablet is-12-mobile pt-0 mb-2">
                                    <b-field
                                      :label="`${$tc('brand',2)} (${$t('optional')})`"
                                    >
                                      <b-taginput
                                        ref="taginput"
                                        v-model="country.brands"
                                        type="is-default"
                                        expanded
                                        autocomplete
                                        field="label"
                                        :disabled="!$can('update', 'purchasing.carriers.pricing')"
                                        ellipsis
                                        :data="filteredBrands"
                                        :placeholder="$t('choose')"
                                        @add="
                                          obj => {
                                            removeFromFilteredBrands(country.brands);
                                          }"
                                        @typing="getBrands"
                                      >
                                        <template v-slot="props">
                                          {{ props.option.label }}
                                        </template>
                                      </b-taginput>
                                    </b-field>
                                  </div>
                                </div>
                              </div>
                              <b-button
                                type="is-primary"
                                :disabled="save_carrier_loading || !$can('update', 'purchasing.carriers.pricing')"
                                :loading="save_carrier_loading"
                                tag="input"
                                native-type="submit"
                                :value="$t('save')"
                                :class="$device.mobile ? 'mt-3' : null"
                              >
                                <!-- @click="savePricing(sld)" -->
                                {{ $t("save") }}
                              </b-button>
                            </form>
                          </ValidationObserver>
                        </b-tab-item>
                      </b-tabs>
                    </div>
                    <!-- </ValidationObserver> -->
                  </template>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </Card>
  </Page>
</template>

<script>
import ImageUpload from "@/components/forms/elements/ImageUpload";

export default {
  components: {
    ImageUpload,
  },
  data() {
    return {
	  renderComponent: false,
      loading: false,
	  save_carrier_loading: false,
	  activeTab: "general",
	  activeSite: 0,
	  activeTld: 0,
	  country_pricing: [],
	  items: {
		name: null,
		product: null,
		code: '',
		type: null,
		tracking_link: '',
		max_kg: '',
		packed_within: '',
		ship_before: '',
		proforma: false,
		rank: '',
		active: false,
		notify_salesman: false,
		max_box: '',
		logo: [],
		id: null,
	  },
	  subtitle: " ",
	  newCountry: null,
	  pricing: null,
	  id: this.$route.params.carrier_id,
	  filteredCountries: [],
	  countries: [],
	  action: null,
	  show_new_zone_form: false,
	  new_zone_loading: false,
	  new_zone: {id: null,
      zone: null,},
	  ship_before: null,
	  zones: [],
	  filters: [],
	  img_size: [],
	  brands: [],
	  filteredBrands: [],
	  codes_mp: [],
	  logo: [],
	  image: null,
	  extra_label: '',
    };
  },
  created() {
	if (this.$route.params.carrier_id) {
		this.updateTitle(`${this.$t("edit")} ${this.$tc("carrier", 1)}`);
		this.getItems(this.$route.params.carrier_id);
		this.action = "edit";
	}
	else {
		this.action = "create";
		this.updateTitle(`${this.$t("create")} ${this.$tc("carrier", 1)}`);
		this.getItems(0);
	}
  },
  methods: {
	async forceRerender() {
      this.renderComponent = true;   
    },
	getItems(id) {
		if (!this.$can("read", "purchasing.carriers")) return;
		this.loading = true;
		this.$axios
			.get(`/carriers/${id}`)
			.then(res  => {
				this.img_size = res.data.filters.img_sizes.logo;
				this.extra_label = '('+this.img_size.height+'x'+this.img_size.width+'px '+ this.img_size.format.toUpperCase()+')';
				this.types = res.data.filters.types;
				if (id != 0) {
					this.items = JSON.parse(JSON.stringify(res.data.carrier));
					if (res.data.carrier.logo) this.logo =  [{ ...res.data.carrier.logo }];
					this.zones = res.data.carrier.zones;
					this.countries = res.data.filters.countries;
					this.brands = res.data.filters.brands;
					this.ship_before = new Date(res.data.ship_before);
					this.pricing = res.data.sites;
					this.subtitle = res.data.carrier.name;
					this.codes_mp = res.data.carrier.codes;
					this.forceRerender(); 
				}
				else if (id == 0) {
					this.codes_mp  = Object.values(res.data.filters.mp_sites);	
					this.renderComponent = true;
					this.items.type = this.types[0].key;
				}
			})
			.catch(e => this.$root.clientError(e))
			.finally(() => (this.loading = false));
	},
	onUpdateFiles(files) {
      this.image = files[0];
    },
    activateOrDesactivateCarrier() {
    if (this.items.active) {
      this.$axios
        .delete(`/carriers/${this.id}`)
        .then(() => {
          this.items.active = false;
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch(e => this.clientError(e));
    } else {
      this.$axios
        .patch(`/carriers/${this.id}/restore`)
        .then(() => {
          this.items.active = true;
          this.$root.notify(this.$t("successfully_restored"), "is-success");
        })
        .catch(e => this.clientError(e));
      }
    },  
	saveCarrier() {
		this.save_carrier_loading = true;
		let url;
		let method;
		const formData = new FormData();
		formData.append("logo", this.image);
		formData.set("code", this.items.code ? this.items.code : '');
		// formData.set("id", this.items.id);
		formData.set("rank", this.items.rank);
		formData.set("max_box", this.items.max_box ? this.items.max_box : '');
		formData.set("max_kg", this.items.max_kg ? this.items.max_kg : '');
		formData.set("name", this.items.name);
		formData.set("packed_within", this.items.packed_within ? this.items.packed_within : '');
		formData.set("ship_before", this.items.ship_before ? this.items.ship_before : '');
		formData.set("tracking_link", this.items.tracking_link ? this.items.tracking_link : '');
		formData.set("product", this.items.product);
		formData.set("notify_salesman", this.items.notify_salesman ? 1 : 0);	
		formData.set("proforma", this.items.proforma ? 1 : 0);
		// formData.set("active", this.items.active ? 1 : 0);
		formData.set("type", this.items.type);
		let arr = [];
		for (let i = 0; i< this.codes_mp.length; i++) {
			var obj = { site_id : this.codes_mp[i].id,
				    code : this.codes_mp[i].code,
			};
		    arr.push(obj);
		}
		var codes_mp = JSON.stringify(arr);
		formData.set("codes", codes_mp);
		if (
			(!this.id) &&
			this.$can("create", "catalog")
		) {
			method = "post";
			url = "carriers";
		} else  {
			method = "post"
			url = `carriers/${this.id}`;
		}
		this.$axios({
        	method: method,
        	url: url,
        	data: formData,
			headers: { "content-type": "multipart/form-data" }
      		})
		.then(res => {
			this.items = res.data.carrier;
			this.zones = res.data.carrier.zones;
			this.countries = res.data.filters.countries;
			this.ship_before = new Date(res.data.ship_before);
			this.pricing = res.data.sites;
			this.id = res.data.carrier.id;
			this.brands = res.data.filters.brands;
			if (url == "carriers") {
				this.$root.notify(this.$t("successfully_saved"), "is-success");
				this.activeTab = "tarification";	
				this.activeSite = "0";		
			}
			else
				this.$root.notify(this.$t("successfully_updated"), "is-success");
		})
		.catch(e => this.$root.clientError(e))
		.finally(() => (this.save_carrier_loading = false));
	},
	savePricing(sld) {
		this.save_carrier_loading = true;
		let data = [];
			for (let i = 0; i< sld.pricing.length; i++) {
				var obj = { country_iso : sld.pricing[i].country.iso,
					rate : sld.pricing[i].rate,
					extra_rate : sld.pricing[i].extra_rate,
					rate_new_box : sld.pricing[i].rate_new_box,
					free_from : sld.pricing[i].free_from,
					delivery_time : sld.pricing[i].delivery_time,
					cashback : sld.pricing[i].cashback,
					brands_free_from :  sld.pricing[i].brands_free_from || null,
					brands_id: [],
				}
				 if(sld.pricing[i].brands) {
				 	for (let j = 0; j< sld.pricing[i].brands.length; j++) {
						// if (
						// 	typeof sld.pricing[i].brands[j] === 'object' &&
						// 	!Array.isArray(sld.pricing[i].brands[j]) &&
						// 	sld.pricing[i].brands[j] !== null
						// ) {
						// 	var brand =  sld.pricing[i].brands[j].id;
						// 	}
					    // else { var brand =  sld.pricing[i].brands[j]; }
						var brand =  sld.pricing[i].brands[j].id;
				 		obj.brands_id.push(brand);
				 	}
				 }
				data.push(obj);
			}
		this.$axios
		.put(`carriers/${this.id}/pricing/${sld.sld_id}`, { pricing : data })
		.then(res => {

			this.$root.notify(this.$t("successfully_updated"), "is-success");
		})
		.catch(e => this.$root.clientError(e))
		.finally(() => (this.save_carrier_loading = false));
	},
	setCountryPricing(obj, sld) {
		var exist = false;
		for (let i = 0; i< sld.pricing.length; i++) {
			if (obj.id == sld.pricing[i].country.iso)
			{ exist = true; }
		}
		if (!exist) {
			var country = {
						country: {iso: obj.id,
								label: obj.label},
						delivery_time: null,
						rate: null,
						extra_rate: null,
						rate_new_box: null,
						free_from: null,
						cashback: null,}
			sld.pricing.unshift(country);
		}
	},
	deleteCountryPricing(country, countries, sld) {
		country.deleted = true ;
		var iso = country.country.iso;
		var index = countries.findIndex(object => {
			return object.country.iso== iso;
		})
		if (index !== -1) {
			this.$axios
          .delete(`carriers/${this.id}/pricing/${sld.sld_id}/${iso}`)
          .then(() => {
			country.country.iso = 0 ;
		    setTimeout(() => {
			   countries.splice(index, 1);
            }, 1000);
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
          .catch((e) => this.clientError(e));

        }
	},
	getCountries(text) {
		this.filteredCountries = this.countries;
    //   if (typeof text == "object") text = "";
        this.filteredCountries = this.countries.filter(option => {
			return (
			option.label
				.toString()
				.toLowerCase()
				.indexOf(text.toLowerCase()) >= 0
			);
        });
	    // var arr = [];

	    // for (let i = 0; i< this.pricing[this.activeSite].tlds[this.activeTld].pricing.length; i++) {
		//     var country = { label: this.pricing[this.activeSite].tlds[this.activeTld].pricing[i].country.label, }
		//     arr.push(country);
		// 			}
        // this.filteredCountries = this.filteredCountries.filter(
        // l => arr.findIndex(s => s.label == l.label) == -1
        // );
    },
	getBrands(text) {
		this.filteredBrands = this.brands;
        this.filteredBrands = this.brands.filter(option => {
			return (
			option.label
				.toString()
				.toLowerCase()
				.indexOf(text.toLowerCase()) >= 0
			);
        });
	    var arr = [];
	     for (let i = 0; i< this.pricing[this.activeSite].tlds[this.activeTld].pricing.length; i++) {
		    var brand = { label: this.pricing[this.activeSite].tlds[this.activeTld].pricing[i].country.label, }
		    arr.push(brand);
					}
        this.filteredBrands = this.filteredBrands.filter(
        l => arr.findIndex(s => s.label == l.label) == -1
        );
    },
    removeFromFilteredBrands(items) {
    //     var obj = items[items.length-1];
    //     var index = this.brands.indexOf(obj);
    //     if (index !== -1) {
    //       this.brands.splice(index, 1);
    //     }
     },

  }
}
</script>